import React from "react";
import { Grid } from "@material-ui/core";
import { Article } from "API";
import CardMobile from "./CardMobile";

// ---------------------------------------------------

type Props = {
  pickUpArticles: Article[];
};

export default function CardsMobile({ pickUpArticles }: Props) {
  return (
    <>
      {pickUpArticles && (
        <Grid container md={12} spacing={2}>
          {pickUpArticles
            .filter((article) => article)
            .map((article: Article, key) => (
              <CardMobile key={key} article={article} />
            ))}
        </Grid>
      )}
    </>
  );
}

CardsMobile.defaultProps = {
  showCategory: false,
};
