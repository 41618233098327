import { makeStyles } from "@material-ui/core";

// --------------------------------------------------

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleBox: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  title: {
    fontWeight: "bold",
  },
  tagsContainer: {
    padding: "40px 20px 32px",
    backgroundColor: "#EFEBE2",
  },
  capitalText: {
    letterSpacing: ".2rem",
    fontWeight: "bold",
  },
  text: {
    fontWeight: "bold",
  },
  linkText: {
    color: theme.palette.common.black,
    "&:hover": {
      color: theme.palette.common.black,
      textDecoration: "underline",
      textDecorationColor: theme.palette.common.black,
    },
  },
  linkContainer: {
    marginTop: "32px",
    textAlign: "right",
  },
  contentsWrapper: {
    maxWidth: "1280px",
    minHeight: "400px",
    margin: "0 auto",
  },
}));

export const useStylesMobile = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "0px",
  },
  titleBox: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  title: {
    fontWeight: "bold",
  },
  tagsContainer: {
    padding: "20px",
    backgroundColor: "#EFEBE2",
  },
  text: {
    fontWeight: "bold",
  },
  capitalText: {
    letterSpacing: ".2rem",
    fontWeight: "bold",
  },
  linkText: {
    color: theme.palette.common.black,
    "&:hover": {
      color: theme.palette.common.black,
      textDecoration: "underline",
      textDecorationColor: theme.palette.common.black,
    },
  },
  linkContainer: {
    marginTop: "32px",
    textAlign: "right",
  },
  tabs: {
    borderBottom: `3px solid black`,
  },
}));
