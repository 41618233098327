import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Home from "components/pages/front/Home";

type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;

  const {
    articles,
    pickups,
    partialHtmls,
    categories,
    youtubes,
    publications,
    tags,
    footers,
    adBanners,
  } = pageContext;

  return (
    <>
      <GatsbySeo
        title="舵オンライン │  船遊びの情報サイト（舵社）"
        description="舵オンラインは、『Kazi』、『ボート倶楽部』を刊行する舵社が運営する、船遊びに関する情報サイト。ヨット、ボート、ボート釣り、クルージング、ボート免許、カヌーなど、プレジャーボートや海に関するさまざまなニュースをお届けします。"
        openGraph={{
          url: `https://kazi-online.com`,
          type: "website",
          title: "舵オンライン │  船遊びの情報サイト（舵社）",
          description:
            "舵オンライン（KAZI online）は、『Kazi』、『ボート倶楽部』を刊行する舵社が運営する、船遊びに関する情報サイト。ヨット、ボート、ボート釣り、クルージング、ボート免許、カヌーなど、プレジャーボートや海に関するさまざまなニュースをお届けします。",
          site_name: "舵オンライン │ 船遊びの情報サイト",
          images: [
            {
              url:
                "https://kazi-online.com/images/KAZIonline_SHARE_1200×630pix.jpg",
              alt: "舵オンライン │  船遊びの情報サイト（舵社）",
            },
          ],
        }}
      />
      <Layout footers={footers}>
        <Home
          articles={articles}
          pickUpArticles={pickups}
          partialHtmls={partialHtmls}
          categories={categories}
          youtubes={youtubes}
          publications={publications}
          tags={tags}
          adBanners={adBanners}
        />
      </Layout>
    </>
  );
}
