import imagesStyles from "assets/jss/material-kit-react/imagesStyles";
import theme from "theme";

export const styles = {
  ...imagesStyles,
  cardTitle: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    display: "block",
    overflow: "hidden",
    "@supports (-webkit-line-clamp: 2)": {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 3,
      wordBreak: "break-all",
      overflow: "hidden",
    },
  },
  root: {
    width: "100%",
  },
  descriptionText: {
    height: "2rem",
    textOverflow: "ellipsis",
    lineHeight: 2,
  },
  category: {
    height: "1rem",
  },
  imageList: {
    minWidth: "145px",
  },
  textMuted: {
    color: "#6c757d",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  a: {
    display: "block",
    paddingRight: "12px",
    position: "relative",
    paddingBottom: "66.7%",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
  },
};

export const stylesMobile = {
  ...imagesStyles,
  cardTitle: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    display: "block",
    overflow: "hidden",
    "@supports (-webkit-line-clamp: 2)": {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 3,
      wordBreak: "break-all",
      overflow: "hidden",
    },
  },
  root: {
    width: "100%",
  },
  descriptionText: {
    height: "2rem",
    textOverflow: "ellipsis",
    lineHeight: 2,
  },
  category: {
    height: "1rem",
  },
  imageList: {
    minWidth: "145px",
  },
  textMuted: {
    color: "#6c757d",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
};
