import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArticleDate from "components/atoms/ArticleDate";
import { getCfUrl } from "settings/storageSettings";
import { Article } from "API";
import { Box } from "@material-ui/core";
import HomeHtml2Text from "components/atoms/HomeHtml2Text";
import { styles } from "./style";

type Props = {
  article: Article;
  isMain: boolean;
};

// @ts-ignore
const useStyles = makeStyles(styles);

// ------------------------------------------------------------

export default function CategoryArticles(props: Props) {
  const { article, isMain } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  return (
    <>
      <a
        href={`/articles/${article.slug}`}
        ref={ref}
        style={{
          display: "block",
          overflow: "hidden",
          position: "relative",
          paddingBottom: "66.7%",
        }}
      >
        <img
          loading="lazy"
          className={classes.imgCardTop}
          src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
          alt={article.title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </a>
      <Box>
        <a href={`/articles/${article.slug}`}>
          <h4 className={classes.cardTitle}>{article.title}</h4>
        </a>
        {isMain && (
          <p className={classes.descriptionText}>
            <HomeHtml2Text value={article.description} />
          </p>
        )}

        {article.startDateTime && (
          <p className={classes.textMuted} style={{ paddingTop: "2rem" }}>
            {article.startDateTime && (
              <ArticleDate date={article.startDateTime} />
            )}
          </p>
        )}
      </Box>
    </>
  );
}

CategoryArticles.defaultProps = {
  showCategory: false,
};
