import { cardTitle } from "assets/jss/material-kit-react";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles";
import theme from "theme";

// --------------------------------------------------

export const styles = {
  ...imagesStyles,
  cardTitle: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "16px",
    height: "4.5rem",
    display: "block",
    overflow: "hidden",
    "@supports (-webkit-line-clamp: 2)": {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 3,
      wordBreak: "break-all",
      overflow: "hidden",
    },
  },
  root: {
    minHeight: "320px",
    width: "100%",
  },
  descriptionText: {
    height: "4rem",
    textOverflow: "ellipsis",
    lineHeight: 1.5,
  },
  category: {
    height: "1rem",
  },
  textMuted: {
    color: "#6c757d",
  },
};

export const stylesMobile = {
  ...imagesStyles,
  cardTitle: {
    ...cardTitle,
    fontSize: "16px",
    display: "block",
    overflow: "hidden",
    height: "4.5rem",
    "@supports (-webkit-line-clamp: 2)": {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 3,
      wordBreak: "break-all",
      overflow: "hidden",
    },
  },
  root: {
    minHeight: "320px",
    width: "100%",
  },
  descriptionText: {
    height: "4rem",
    textOverflow: "ellipsis",
    lineHeight: 1.5,
  },
  category: {
    height: "1rem",
  },
  textMuted: {
    color: "#6c757d",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
};
