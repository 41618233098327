/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateArticleInput = {
  id?: string | null,
  title: string,
  slug: string,
  description: string,
  descriptionText?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  categoryID: string,
  authorID: string,
  prFlg?: boolean | null,
  adID?: string | null,
  manuscriptID?: string | null,
  status?: string | null,
  connectionID?: string | null,
  relatedArticles?: Array< string | null > | null,
  articleMainImageId?: string | null,
};

export type ModelArticleConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  descriptionText?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  prFlg?: ModelBooleanInput | null,
  adID?: ModelIDInput | null,
  manuscriptID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  connectionID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  relatedArticles?: ModelIDInput | null,
  and?: Array< ModelArticleConditionInput | null > | null,
  or?: Array< ModelArticleConditionInput | null > | null,
  not?: ModelArticleConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Article = {
  __typename: "Article",
  id: string,
  title: string,
  slug: string,
  description: string,
  descriptionText?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  categoryID: string,
  authorID: string,
  prFlg?: boolean | null,
  adID?: string | null,
  manuscriptID?: string | null,
  status?: string | null,
  connectionID?: string | null,
  version: number,
  relatedArticles?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  category?: Category | null,
  tags?: ModelArticleTagNewConnection | null,
  mainImage?: Media | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  jName?: string | null,
  slug?: string | null,
  url?: string | null,
  description?: string | null,
  priority?: number | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  mainImage?: Media | null,
};

export type Media = {
  __typename: "Media",
  id: string,
  name: string,
  storage: S3Object,
  url: string,
  version: number,
  createdAt?: string | null,
  updatedAt: string,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type ModelArticleTagNewConnection = {
  __typename: "ModelArticleTagNewConnection",
  items:  Array<ArticleTagNew | null >,
  nextToken?: string | null,
};

export type ArticleTagNew = {
  __typename: "ArticleTagNew",
  id: string,
  articleID: string,
  tagID: string,
  createdAt: string,
  updatedAt: string,
  article?: Article | null,
  tag?: Tag | null,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  name: string,
  slug?: string | null,
  categoryID?: string | null,
  priority?: number | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  articles?: ModelArticleTagNewConnection | null,
};

export type UpdateArticleInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  description?: string | null,
  descriptionText?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  categoryID?: string | null,
  authorID?: string | null,
  prFlg?: boolean | null,
  adID?: string | null,
  manuscriptID?: string | null,
  status?: string | null,
  connectionID?: string | null,
  version?: number | null,
  relatedArticles?: Array< string | null > | null,
  expectedVersion: number,
  articleMainImageId?: string | null,
};

export type DeleteArticleInput = {
  id: string,
  expectedVersion: number,
};

export type CreateTopImageInput = {
  id?: string | null,
  priority?: number | null,
  startDateTime: string,
  endDateTime: string,
  url?: string | null,
  topImageMainImageId?: string | null,
};

export type ModelTopImageConditionInput = {
  priority?: ModelIntInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  url?: ModelStringInput | null,
  and?: Array< ModelTopImageConditionInput | null > | null,
  or?: Array< ModelTopImageConditionInput | null > | null,
  not?: ModelTopImageConditionInput | null,
};

export type TopImage = {
  __typename: "TopImage",
  id: string,
  priority?: number | null,
  startDateTime: string,
  endDateTime: string,
  url?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
  mainImage?: Media | null,
};

export type UpdateTopImageInput = {
  id: string,
  priority?: number | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  url?: string | null,
  expectedVersion: number,
  topImageMainImageId?: string | null,
};

export type DeleteTopImageInput = {
  id: string,
  expectedVersion: number,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
  jName?: string | null,
  slug?: string | null,
  url?: string | null,
  description?: string | null,
  priority?: number | null,
  categoryMainImageId?: string | null,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  jName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  url?: ModelStringInput | null,
  description?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  jName?: string | null,
  slug?: string | null,
  url?: string | null,
  description?: string | null,
  priority?: number | null,
  version?: number | null,
  expectedVersion: number,
  categoryMainImageId?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
  expectedVersion: number,
};

export type CreateArticleTagInput = {
  id?: string | null,
  articleID: string,
  tagID: string,
};

export type ModelArticleTagConditionInput = {
  articleID?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelArticleTagConditionInput | null > | null,
  or?: Array< ModelArticleTagConditionInput | null > | null,
  not?: ModelArticleTagConditionInput | null,
};

export type ArticleTag = {
  __typename: "ArticleTag",
  id: string,
  articleID: string,
  tagID: string,
  version: number,
  createdAt: string,
  updatedAt: string,
  articles?: ModelArticleConnection | null,
  tag?: Tag | null,
};

export type ModelArticleConnection = {
  __typename: "ModelArticleConnection",
  items:  Array<Article | null >,
  nextToken?: string | null,
};

export type UpdateArticleTagInput = {
  id: string,
  articleID?: string | null,
  tagID?: string | null,
  version?: number | null,
  expectedVersion: number,
};

export type DeleteArticleTagInput = {
  id: string,
  expectedVersion: number,
};

export type CreateArticleTagNewInput = {
  id?: string | null,
  articleID: string,
  tagID: string,
};

export type ModelArticleTagNewConditionInput = {
  articleID?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  and?: Array< ModelArticleTagNewConditionInput | null > | null,
  or?: Array< ModelArticleTagNewConditionInput | null > | null,
  not?: ModelArticleTagNewConditionInput | null,
};

export type UpdateArticleTagNewInput = {
  id: string,
  articleID?: string | null,
  tagID?: string | null,
};

export type DeleteArticleTagNewInput = {
  id: string,
};

export type CreateTagInput = {
  id?: string | null,
  name: string,
  slug?: string | null,
  categoryID?: string | null,
  priority?: number | null,
};

export type ModelTagConditionInput = {
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelTagConditionInput | null > | null,
  or?: Array< ModelTagConditionInput | null > | null,
  not?: ModelTagConditionInput | null,
};

export type UpdateTagInput = {
  id: string,
  name?: string | null,
  slug?: string | null,
  categoryID?: string | null,
  priority?: number | null,
  version?: number | null,
  expectedVersion: number,
};

export type DeleteTagInput = {
  id: string,
  expectedVersion: number,
};

export type CreateAuthorInput = {
  id?: string | null,
  name: string,
  email?: string | null,
  status?: ActiveStatus | null,
  authorImageId?: string | null,
};

export enum ActiveStatus {
  active = "active",
  inactive = "inactive",
}


export type ModelAuthorConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelAuthorConditionInput | null > | null,
  or?: Array< ModelAuthorConditionInput | null > | null,
  not?: ModelAuthorConditionInput | null,
};

export type ModelActiveStatusInput = {
  eq?: ActiveStatus | null,
  ne?: ActiveStatus | null,
};

export type Author = {
  __typename: "Author",
  id: string,
  name: string,
  email?: string | null,
  status?: ActiveStatus | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  image?: Media | null,
};

export type UpdateAuthorInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  status?: ActiveStatus | null,
  version?: number | null,
  expectedVersion: number,
  authorImageId?: string | null,
};

export type DeleteAuthorInput = {
  id: string,
  expectedVersion: number,
};

export type CreateArticlePickupInput = {
  id?: string | null,
  categoryID?: string | null,
  articleID: string,
  position: Position,
  priority?: number | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
};

export enum Position {
  topheader = "topheader",
  topfooter = "topfooter",
  ad = "ad",
  pr = "pr",
  articlefooter = "articlefooter",
}


export type ModelArticlePickupConditionInput = {
  categoryID?: ModelIDInput | null,
  articleID?: ModelIDInput | null,
  position?: ModelPositionInput | null,
  priority?: ModelIntInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelArticlePickupConditionInput | null > | null,
  or?: Array< ModelArticlePickupConditionInput | null > | null,
  not?: ModelArticlePickupConditionInput | null,
};

export type ModelPositionInput = {
  eq?: Position | null,
  ne?: Position | null,
};

export type ArticlePickup = {
  __typename: "ArticlePickup",
  id: string,
  categoryID?: string | null,
  articleID: string,
  position: Position,
  priority?: number | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  article?: Article | null,
  articles?: ModelArticleConnection | null,
};

export type UpdateArticlePickupInput = {
  id: string,
  categoryID?: string | null,
  articleID?: string | null,
  position?: Position | null,
  priority?: number | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  version?: number | null,
  expectedVersion: number,
};

export type DeleteArticlePickupInput = {
  id: string,
  expectedVersion: number,
};

export type CreateAdInput = {
  id?: string | null,
  name: string,
  url: string,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status: Status,
  priority?: number | null,
};

export enum Status {
  draft = "draft",
  publish = "publish",
}


export type ModelAdConditionInput = {
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelAdConditionInput | null > | null,
  or?: Array< ModelAdConditionInput | null > | null,
  not?: ModelAdConditionInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type Ad = {
  __typename: "Ad",
  id: string,
  name: string,
  url: string,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status: Status,
  priority?: number | null,
  version: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAdInput = {
  id: string,
  name?: string | null,
  url?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status?: Status | null,
  priority?: number | null,
  version?: number | null,
  expectedVersion: number,
};

export type DeleteAdInput = {
  id: string,
  expectedVersion: number,
};

export type CreateAdBannerInput = {
  id?: string | null,
  categoryID?: string | null,
  adID: string,
  position: string,
  priority?: number | null,
  adBannerMediaId?: string | null,
  adBannerMediaPCId?: string | null,
};

export type ModelAdBannerConditionInput = {
  categoryID?: ModelIDInput | null,
  adID?: ModelIDInput | null,
  position?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelAdBannerConditionInput | null > | null,
  or?: Array< ModelAdBannerConditionInput | null > | null,
  not?: ModelAdBannerConditionInput | null,
};

export type AdBanner = {
  __typename: "AdBanner",
  id: string,
  categoryID?: string | null,
  adID: string,
  position: string,
  priority?: number | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  ad?: Ad | null,
  media?: Media | null,
  mediaPC?: Media | null,
};

export type UpdateAdBannerInput = {
  id: string,
  categoryID?: string | null,
  adID?: string | null,
  position?: string | null,
  priority?: number | null,
  version?: number | null,
  expectedVersion: number,
  adBannerMediaId?: string | null,
  adBannerMediaPCId?: string | null,
};

export type DeleteAdBannerInput = {
  id: string,
  expectedVersion: number,
};

export type CreateMediaInput = {
  id?: string | null,
  name: string,
  storage: S3ObjectInput,
  url: string,
  createdAt?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export type ModelMediaConditionInput = {
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  version?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMediaConditionInput | null > | null,
  or?: Array< ModelMediaConditionInput | null > | null,
  not?: ModelMediaConditionInput | null,
};

export type UpdateMediaInput = {
  id: string,
  name?: string | null,
  storage?: S3ObjectInput | null,
  url?: string | null,
  version?: number | null,
  createdAt?: string | null,
  expectedVersion: number,
};

export type DeleteMediaInput = {
  id: string,
  expectedVersion: number,
};

export type CreateManuscriptInput = {
  id?: string | null,
  title: string,
  slug: string,
  description: string,
  descriptionText?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  categoryID: string,
  authorID: string,
  prFlg?: boolean | null,
  adID?: string | null,
  status?: string | null,
  manuscriptMainImageId?: string | null,
};

export type ModelManuscriptConditionInput = {
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  descriptionText?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  prFlg?: ModelBooleanInput | null,
  adID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelManuscriptConditionInput | null > | null,
  or?: Array< ModelManuscriptConditionInput | null > | null,
  not?: ModelManuscriptConditionInput | null,
};

export type Manuscript = {
  __typename: "Manuscript",
  id: string,
  title: string,
  slug: string,
  description: string,
  descriptionText?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  categoryID: string,
  authorID: string,
  prFlg?: boolean | null,
  adID?: string | null,
  status?: string | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  mainImage?: Media | null,
};

export type UpdateManuscriptInput = {
  id: string,
  title?: string | null,
  slug?: string | null,
  description?: string | null,
  descriptionText?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  categoryID?: string | null,
  authorID?: string | null,
  prFlg?: boolean | null,
  adID?: string | null,
  status?: string | null,
  version?: number | null,
  expectedVersion: number,
  manuscriptMainImageId?: string | null,
};

export type DeleteManuscriptInput = {
  id: string,
  expectedVersion: number,
};

export type CreateManuscriptTagInput = {
  id?: string | null,
  articleID: string,
  tagID: string,
};

export type ModelManuscriptTagConditionInput = {
  articleID?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelManuscriptTagConditionInput | null > | null,
  or?: Array< ModelManuscriptTagConditionInput | null > | null,
  not?: ModelManuscriptTagConditionInput | null,
};

export type ManuscriptTag = {
  __typename: "ManuscriptTag",
  id: string,
  articleID: string,
  tagID: string,
  article?: Manuscript | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  tag?: Tag | null,
};

export type UpdateManuscriptTagInput = {
  id: string,
  articleID?: string | null,
  tagID?: string | null,
  version?: number | null,
  expectedVersion: number,
};

export type DeleteManuscriptTagInput = {
  id: string,
  expectedVersion: number,
};

export type CreateContentOwnerInput = {
  id?: string | null,
  name: string,
  email: string,
  status?: ActiveStatus | null,
  owner?: string | null,
};

export type ModelContentOwnerConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelContentOwnerConditionInput | null > | null,
  or?: Array< ModelContentOwnerConditionInput | null > | null,
  not?: ModelContentOwnerConditionInput | null,
};

export type ContentOwner = {
  __typename: "ContentOwner",
  id: string,
  name: string,
  email: string,
  status?: ActiveStatus | null,
  version: number,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateContentOwnerInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  status?: ActiveStatus | null,
  version?: number | null,
  owner?: string | null,
  expectedVersion: number,
};

export type DeleteContentOwnerInput = {
  id: string,
  expectedVersion: number,
};

export type CreatePageInput = {
  id?: string | null,
  root: string,
  slug: string,
  title: string,
  discription?: string | null,
  contents?: string | null,
  status?: string | null,
  pageBackgroundImageId?: string | null,
};

export type ModelPageConditionInput = {
  root?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  title?: ModelStringInput | null,
  discription?: ModelStringInput | null,
  contents?: ModelStringInput | null,
  status?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
};

export type Page = {
  __typename: "Page",
  id: string,
  root: string,
  slug: string,
  title: string,
  discription?: string | null,
  contents?: string | null,
  status?: string | null,
  version: number,
  createdAt: string,
  updatedAt: string,
  backgroundImage?: Media | null,
};

export type UpdatePageInput = {
  id: string,
  root?: string | null,
  slug?: string | null,
  title?: string | null,
  discription?: string | null,
  contents?: string | null,
  status?: string | null,
  version?: number | null,
  expectedVersion: number,
  pageBackgroundImageId?: string | null,
};

export type DeletePageInput = {
  id: string,
  expectedVersion: number,
};

export type CreatePartialHtmlInput = {
  id?: string | null,
  name: string,
  contents: string,
  priority: number,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status: ActiveStatus,
  partialHtmlBackgroundImageId?: string | null,
};

export type ModelPartialHtmlConditionInput = {
  name?: ModelStringInput | null,
  contents?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  and?: Array< ModelPartialHtmlConditionInput | null > | null,
  or?: Array< ModelPartialHtmlConditionInput | null > | null,
  not?: ModelPartialHtmlConditionInput | null,
};

export type PartialHtml = {
  __typename: "PartialHtml",
  id: string,
  name: string,
  contents: string,
  priority: number,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status: ActiveStatus,
  createdAt: string,
  updatedAt: string,
  backgroundImage?: Media | null,
};

export type UpdatePartialHtmlInput = {
  id: string,
  name?: string | null,
  contents?: string | null,
  priority?: number | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status?: ActiveStatus | null,
  partialHtmlBackgroundImageId?: string | null,
};

export type DeletePartialHtmlInput = {
  id: string,
};

export type CreatePublicationInput = {
  id?: string | null,
  name: string,
  link: string,
  priority: number,
  status: ActiveStatus,
  publicationImageId?: string | null,
};

export type ModelPublicationConditionInput = {
  name?: ModelStringInput | null,
  link?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  status?: ModelActiveStatusInput | null,
  and?: Array< ModelPublicationConditionInput | null > | null,
  or?: Array< ModelPublicationConditionInput | null > | null,
  not?: ModelPublicationConditionInput | null,
};

export type Publication = {
  __typename: "Publication",
  id: string,
  name: string,
  link: string,
  priority: number,
  status: ActiveStatus,
  createdAt: string,
  updatedAt: string,
  image?: Media | null,
};

export type UpdatePublicationInput = {
  id: string,
  name?: string | null,
  link?: string | null,
  priority?: number | null,
  status?: ActiveStatus | null,
  publicationImageId?: string | null,
};

export type DeletePublicationInput = {
  id: string,
};

export type CreateYoutubeInput = {
  id?: string | null,
  videoId: string,
  status: ActiveStatus,
  priority: number,
  startTime?: number | null,
  isAutoPlayed?: boolean | null,
};

export type ModelYoutubeConditionInput = {
  videoId?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  priority?: ModelIntInput | null,
  startTime?: ModelIntInput | null,
  isAutoPlayed?: ModelBooleanInput | null,
  and?: Array< ModelYoutubeConditionInput | null > | null,
  or?: Array< ModelYoutubeConditionInput | null > | null,
  not?: ModelYoutubeConditionInput | null,
};

export type Youtube = {
  __typename: "Youtube",
  id: string,
  videoId: string,
  status: ActiveStatus,
  priority: number,
  startTime?: number | null,
  isAutoPlayed?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateYoutubeInput = {
  id: string,
  videoId?: string | null,
  status?: ActiveStatus | null,
  priority?: number | null,
  startTime?: number | null,
  isAutoPlayed?: boolean | null,
};

export type DeleteYoutubeInput = {
  id: string,
};

export type CreateFooterInput = {
  id?: string | null,
  name: string,
  link: string,
  priority: number,
  position: number,
};

export type ModelFooterConditionInput = {
  name?: ModelStringInput | null,
  link?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelFooterConditionInput | null > | null,
  or?: Array< ModelFooterConditionInput | null > | null,
  not?: ModelFooterConditionInput | null,
};

export type Footer = {
  __typename: "Footer",
  id: string,
  name: string,
  link: string,
  priority: number,
  position: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFooterInput = {
  id: string,
  name?: string | null,
  link?: string | null,
  priority?: number | null,
  position?: number | null,
};

export type DeleteFooterInput = {
  id: string,
};

export type ModelManuscriptFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  descriptionText?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  prFlg?: ModelBooleanInput | null,
  adID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelManuscriptFilterInput | null > | null,
  or?: Array< ModelManuscriptFilterInput | null > | null,
  not?: ModelManuscriptFilterInput | null,
};

export type ModelManuscriptConnection = {
  __typename: "ModelManuscriptConnection",
  items:  Array<Manuscript | null >,
  nextToken?: string | null,
};

export type ModelManuscriptTagFilterInput = {
  id?: ModelIDInput | null,
  articleID?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelManuscriptTagFilterInput | null > | null,
  or?: Array< ModelManuscriptTagFilterInput | null > | null,
  not?: ModelManuscriptTagFilterInput | null,
};

export type ModelManuscriptTagConnection = {
  __typename: "ModelManuscriptTagConnection",
  items:  Array<ManuscriptTag | null >,
  nextToken?: string | null,
};

export type ModelContentOwnerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  version?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelContentOwnerFilterInput | null > | null,
  or?: Array< ModelContentOwnerFilterInput | null > | null,
  not?: ModelContentOwnerFilterInput | null,
};

export type ModelContentOwnerConnection = {
  __typename: "ModelContentOwnerConnection",
  items:  Array<ContentOwner | null >,
  nextToken?: string | null,
};

export type ModelArticleFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  description?: ModelStringInput | null,
  descriptionText?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  prFlg?: ModelBooleanInput | null,
  adID?: ModelIDInput | null,
  manuscriptID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  connectionID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  relatedArticles?: ModelIDInput | null,
  and?: Array< ModelArticleFilterInput | null > | null,
  or?: Array< ModelArticleFilterInput | null > | null,
  not?: ModelArticleFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelArticleListArticleByStatusAndCategoryCompositeKeyConditionInput = {
  eq?: ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null,
  le?: ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null,
  lt?: ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null,
  ge?: ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null,
  gt?: ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null,
  between?: Array< ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null > | null,
  beginsWith?: ModelArticleListArticleByStatusAndCategoryCompositeKeyInput | null,
};

export type ModelArticleListArticleByStatusAndCategoryCompositeKeyInput = {
  startDateTime?: string | null,
  status?: string | null,
};

export type SearchableArticleFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  slug?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  descriptionText?: SearchableStringFilterInput | null,
  startDateTime?: SearchableStringFilterInput | null,
  endDateTime?: SearchableStringFilterInput | null,
  categoryID?: SearchableIDFilterInput | null,
  authorID?: SearchableIDFilterInput | null,
  prFlg?: SearchableBooleanFilterInput | null,
  adID?: SearchableIDFilterInput | null,
  manuscriptID?: SearchableIDFilterInput | null,
  status?: SearchableStringFilterInput | null,
  connectionID?: SearchableIDFilterInput | null,
  version?: SearchableIntFilterInput | null,
  relatedArticles?: SearchableIDFilterInput | null,
  and?: Array< SearchableArticleFilterInput | null > | null,
  or?: Array< SearchableArticleFilterInput | null > | null,
  not?: SearchableArticleFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableArticleSortInput = {
  field?: SearchableArticleSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableArticleSortableFields {
  id = "id",
  title = "title",
  slug = "slug",
  description = "description",
  descriptionText = "descriptionText",
  startDateTime = "startDateTime",
  endDateTime = "endDateTime",
  categoryID = "categoryID",
  authorID = "authorID",
  prFlg = "prFlg",
  adID = "adID",
  manuscriptID = "manuscriptID",
  status = "status",
  connectionID = "connectionID",
  version = "version",
  relatedArticles = "relatedArticles",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableArticleConnection = {
  __typename: "SearchableArticleConnection",
  items:  Array<Article | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelTopImageFilterInput = {
  id?: ModelIDInput | null,
  priority?: ModelIntInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  url?: ModelStringInput | null,
  and?: Array< ModelTopImageFilterInput | null > | null,
  or?: Array< ModelTopImageFilterInput | null > | null,
  not?: ModelTopImageFilterInput | null,
};

export type ModelTopImageConnection = {
  __typename: "ModelTopImageConnection",
  items:  Array<TopImage | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  jName?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  url?: ModelStringInput | null,
  description?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelArticleTagFilterInput = {
  id?: ModelIDInput | null,
  articleID?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelArticleTagFilterInput | null > | null,
  or?: Array< ModelArticleTagFilterInput | null > | null,
  not?: ModelArticleTagFilterInput | null,
};

export type ModelArticleTagConnection = {
  __typename: "ModelArticleTagConnection",
  items:  Array<ArticleTag | null >,
  nextToken?: string | null,
};

export type ModelArticleTagNewFilterInput = {
  id?: ModelIDInput | null,
  articleID?: ModelIDInput | null,
  tagID?: ModelIDInput | null,
  and?: Array< ModelArticleTagNewFilterInput | null > | null,
  or?: Array< ModelArticleTagNewFilterInput | null > | null,
  not?: ModelArticleTagNewFilterInput | null,
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
};

export type ModelAuthorFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelAuthorFilterInput | null > | null,
  or?: Array< ModelAuthorFilterInput | null > | null,
  not?: ModelAuthorFilterInput | null,
};

export type ModelAuthorConnection = {
  __typename: "ModelAuthorConnection",
  items:  Array<Author | null >,
  nextToken?: string | null,
};

export type ModelArticlePickupFilterInput = {
  id?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  articleID?: ModelIDInput | null,
  position?: ModelPositionInput | null,
  priority?: ModelIntInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelArticlePickupFilterInput | null > | null,
  or?: Array< ModelArticlePickupFilterInput | null > | null,
  not?: ModelArticlePickupFilterInput | null,
};

export type ModelArticlePickupConnection = {
  __typename: "ModelArticlePickupConnection",
  items:  Array<ArticlePickup | null >,
  nextToken?: string | null,
};

export type ModelAdFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelAdFilterInput | null > | null,
  or?: Array< ModelAdFilterInput | null > | null,
  not?: ModelAdFilterInput | null,
};

export type ModelAdConnection = {
  __typename: "ModelAdConnection",
  items:  Array<Ad | null >,
  nextToken?: string | null,
};

export type ModelAdBannerFilterInput = {
  id?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  adID?: ModelIDInput | null,
  position?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelAdBannerFilterInput | null > | null,
  or?: Array< ModelAdBannerFilterInput | null > | null,
  not?: ModelAdBannerFilterInput | null,
};

export type ModelAdBannerConnection = {
  __typename: "ModelAdBannerConnection",
  items:  Array<AdBanner | null >,
  nextToken?: string | null,
};

export type ModelMediaFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  version?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMediaFilterInput | null > | null,
  or?: Array< ModelMediaFilterInput | null > | null,
  not?: ModelMediaFilterInput | null,
};

export type ModelMediaConnection = {
  __typename: "ModelMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
};

export type SearchableMediaFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  url?: SearchableStringFilterInput | null,
  version?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableMediaFilterInput | null > | null,
  or?: Array< SearchableMediaFilterInput | null > | null,
  not?: SearchableMediaFilterInput | null,
};

export type SearchableMediaSortInput = {
  field?: SearchableMediaSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMediaSortableFields {
  id = "id",
  name = "name",
  url = "url",
  version = "version",
  createdAt = "createdAt",
}


export type SearchableMediaConnection = {
  __typename: "SearchableMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  root?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  title?: ModelStringInput | null,
  discription?: ModelStringInput | null,
  contents?: ModelStringInput | null,
  status?: ModelStringInput | null,
  version?: ModelIntInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items:  Array<Page | null >,
  nextToken?: string | null,
};

export type ModelPartialHtmlFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  contents?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  and?: Array< ModelPartialHtmlFilterInput | null > | null,
  or?: Array< ModelPartialHtmlFilterInput | null > | null,
  not?: ModelPartialHtmlFilterInput | null,
};

export type ModelPartialHtmlConnection = {
  __typename: "ModelPartialHtmlConnection",
  items:  Array<PartialHtml | null >,
  nextToken?: string | null,
};

export type ModelPublicationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  link?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  status?: ModelActiveStatusInput | null,
  and?: Array< ModelPublicationFilterInput | null > | null,
  or?: Array< ModelPublicationFilterInput | null > | null,
  not?: ModelPublicationFilterInput | null,
};

export type ModelPublicationConnection = {
  __typename: "ModelPublicationConnection",
  items:  Array<Publication | null >,
  nextToken?: string | null,
};

export type ModelYoutubeFilterInput = {
  id?: ModelIDInput | null,
  videoId?: ModelStringInput | null,
  status?: ModelActiveStatusInput | null,
  priority?: ModelIntInput | null,
  startTime?: ModelIntInput | null,
  isAutoPlayed?: ModelBooleanInput | null,
  and?: Array< ModelYoutubeFilterInput | null > | null,
  or?: Array< ModelYoutubeFilterInput | null > | null,
  not?: ModelYoutubeFilterInput | null,
};

export type ModelYoutubeConnection = {
  __typename: "ModelYoutubeConnection",
  items:  Array<Youtube | null >,
  nextToken?: string | null,
};

export type ModelFooterFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  link?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  position?: ModelIntInput | null,
  and?: Array< ModelFooterFilterInput | null > | null,
  or?: Array< ModelFooterFilterInput | null > | null,
  not?: ModelFooterFilterInput | null,
};

export type ModelFooterConnection = {
  __typename: "ModelFooterConnection",
  items:  Array<Footer | null >,
  nextToken?: string | null,
};

export type CreateArticleMutationVariables = {
  input: CreateArticleInput,
  condition?: ModelArticleConditionInput | null,
};

export type CreateArticleMutation = {
  createArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateArticleMutationVariables = {
  input: UpdateArticleInput,
  condition?: ModelArticleConditionInput | null,
};

export type UpdateArticleMutation = {
  updateArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteArticleMutationVariables = {
  input: DeleteArticleInput,
  condition?: ModelArticleConditionInput | null,
};

export type DeleteArticleMutation = {
  deleteArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateTopImageMutationVariables = {
  input: CreateTopImageInput,
  condition?: ModelTopImageConditionInput | null,
};

export type CreateTopImageMutation = {
  createTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateTopImageMutationVariables = {
  input: UpdateTopImageInput,
  condition?: ModelTopImageConditionInput | null,
};

export type UpdateTopImageMutation = {
  updateTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteTopImageMutationVariables = {
  input: DeleteTopImageInput,
  condition?: ModelTopImageConditionInput | null,
};

export type DeleteTopImageMutation = {
  deleteTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateArticleTagMutationVariables = {
  input: CreateArticleTagInput,
  condition?: ModelArticleTagConditionInput | null,
};

export type CreateArticleTagMutation = {
  createArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateArticleTagMutationVariables = {
  input: UpdateArticleTagInput,
  condition?: ModelArticleTagConditionInput | null,
};

export type UpdateArticleTagMutation = {
  updateArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteArticleTagMutationVariables = {
  input: DeleteArticleTagInput,
  condition?: ModelArticleTagConditionInput | null,
};

export type DeleteArticleTagMutation = {
  deleteArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateArticleTagNewMutationVariables = {
  input: CreateArticleTagNewInput,
  condition?: ModelArticleTagNewConditionInput | null,
};

export type CreateArticleTagNewMutation = {
  createArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateArticleTagNewMutationVariables = {
  input: UpdateArticleTagNewInput,
  condition?: ModelArticleTagNewConditionInput | null,
};

export type UpdateArticleTagNewMutation = {
  updateArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteArticleTagNewMutationVariables = {
  input: DeleteArticleTagNewInput,
  condition?: ModelArticleTagNewConditionInput | null,
};

export type DeleteArticleTagNewMutation = {
  deleteArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
  condition?: ModelTagConditionInput | null,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAuthorMutationVariables = {
  input: CreateAuthorInput,
  condition?: ModelAuthorConditionInput | null,
};

export type CreateAuthorMutation = {
  createAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateAuthorMutationVariables = {
  input: UpdateAuthorInput,
  condition?: ModelAuthorConditionInput | null,
};

export type UpdateAuthorMutation = {
  updateAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteAuthorMutationVariables = {
  input: DeleteAuthorInput,
  condition?: ModelAuthorConditionInput | null,
};

export type DeleteAuthorMutation = {
  deleteAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateArticlePickupMutationVariables = {
  input: CreateArticlePickupInput,
  condition?: ModelArticlePickupConditionInput | null,
};

export type CreateArticlePickupMutation = {
  createArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateArticlePickupMutationVariables = {
  input: UpdateArticlePickupInput,
  condition?: ModelArticlePickupConditionInput | null,
};

export type UpdateArticlePickupMutation = {
  updateArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteArticlePickupMutationVariables = {
  input: DeleteArticlePickupInput,
  condition?: ModelArticlePickupConditionInput | null,
};

export type DeleteArticlePickupMutation = {
  deleteArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAdMutationVariables = {
  input: CreateAdInput,
  condition?: ModelAdConditionInput | null,
};

export type CreateAdMutation = {
  createAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAdMutationVariables = {
  input: UpdateAdInput,
  condition?: ModelAdConditionInput | null,
};

export type UpdateAdMutation = {
  updateAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAdMutationVariables = {
  input: DeleteAdInput,
  condition?: ModelAdConditionInput | null,
};

export type DeleteAdMutation = {
  deleteAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAdBannerMutationVariables = {
  input: CreateAdBannerInput,
  condition?: ModelAdBannerConditionInput | null,
};

export type CreateAdBannerMutation = {
  createAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateAdBannerMutationVariables = {
  input: UpdateAdBannerInput,
  condition?: ModelAdBannerConditionInput | null,
};

export type UpdateAdBannerMutation = {
  updateAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteAdBannerMutationVariables = {
  input: DeleteAdBannerInput,
  condition?: ModelAdBannerConditionInput | null,
};

export type DeleteAdBannerMutation = {
  deleteAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateMediaMutationVariables = {
  input: CreateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type CreateMediaMutation = {
  createMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateMediaMutationVariables = {
  input: UpdateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type UpdateMediaMutation = {
  updateMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteMediaMutationVariables = {
  input: DeleteMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type DeleteMediaMutation = {
  deleteMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateManuscriptMutationVariables = {
  input: CreateManuscriptInput,
  condition?: ModelManuscriptConditionInput | null,
};

export type CreateManuscriptMutation = {
  createManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateManuscriptMutationVariables = {
  input: UpdateManuscriptInput,
  condition?: ModelManuscriptConditionInput | null,
};

export type UpdateManuscriptMutation = {
  updateManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteManuscriptMutationVariables = {
  input: DeleteManuscriptInput,
  condition?: ModelManuscriptConditionInput | null,
};

export type DeleteManuscriptMutation = {
  deleteManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateManuscriptTagMutationVariables = {
  input: CreateManuscriptTagInput,
  condition?: ModelManuscriptTagConditionInput | null,
};

export type CreateManuscriptTagMutation = {
  createManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateManuscriptTagMutationVariables = {
  input: UpdateManuscriptTagInput,
  condition?: ModelManuscriptTagConditionInput | null,
};

export type UpdateManuscriptTagMutation = {
  updateManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteManuscriptTagMutationVariables = {
  input: DeleteManuscriptTagInput,
  condition?: ModelManuscriptTagConditionInput | null,
};

export type DeleteManuscriptTagMutation = {
  deleteManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateContentOwnerMutationVariables = {
  input: CreateContentOwnerInput,
  condition?: ModelContentOwnerConditionInput | null,
};

export type CreateContentOwnerMutation = {
  createContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentOwnerMutationVariables = {
  input: UpdateContentOwnerInput,
  condition?: ModelContentOwnerConditionInput | null,
};

export type UpdateContentOwnerMutation = {
  updateContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentOwnerMutationVariables = {
  input: DeleteContentOwnerInput,
  condition?: ModelContentOwnerConditionInput | null,
};

export type DeleteContentOwnerMutation = {
  deleteContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePageMutationVariables = {
  input: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreatePartialHtmlMutationVariables = {
  input: CreatePartialHtmlInput,
  condition?: ModelPartialHtmlConditionInput | null,
};

export type CreatePartialHtmlMutation = {
  createPartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdatePartialHtmlMutationVariables = {
  input: UpdatePartialHtmlInput,
  condition?: ModelPartialHtmlConditionInput | null,
};

export type UpdatePartialHtmlMutation = {
  updatePartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeletePartialHtmlMutationVariables = {
  input: DeletePartialHtmlInput,
  condition?: ModelPartialHtmlConditionInput | null,
};

export type DeletePartialHtmlMutation = {
  deletePartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreatePublicationMutationVariables = {
  input: CreatePublicationInput,
  condition?: ModelPublicationConditionInput | null,
};

export type CreatePublicationMutation = {
  createPublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdatePublicationMutationVariables = {
  input: UpdatePublicationInput,
  condition?: ModelPublicationConditionInput | null,
};

export type UpdatePublicationMutation = {
  updatePublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeletePublicationMutationVariables = {
  input: DeletePublicationInput,
  condition?: ModelPublicationConditionInput | null,
};

export type DeletePublicationMutation = {
  deletePublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateYoutubeMutationVariables = {
  input: CreateYoutubeInput,
  condition?: ModelYoutubeConditionInput | null,
};

export type CreateYoutubeMutation = {
  createYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateYoutubeMutationVariables = {
  input: UpdateYoutubeInput,
  condition?: ModelYoutubeConditionInput | null,
};

export type UpdateYoutubeMutation = {
  updateYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteYoutubeMutationVariables = {
  input: DeleteYoutubeInput,
  condition?: ModelYoutubeConditionInput | null,
};

export type DeleteYoutubeMutation = {
  deleteYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFooterMutationVariables = {
  input: CreateFooterInput,
  condition?: ModelFooterConditionInput | null,
};

export type CreateFooterMutation = {
  createFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFooterMutationVariables = {
  input: UpdateFooterInput,
  condition?: ModelFooterConditionInput | null,
};

export type UpdateFooterMutation = {
  updateFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFooterMutationVariables = {
  input: DeleteFooterInput,
  condition?: ModelFooterConditionInput | null,
};

export type DeleteFooterMutation = {
  deleteFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetManuscriptQueryVariables = {
  id: string,
};

export type GetManuscriptQuery = {
  getManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListManuscriptsQueryVariables = {
  filter?: ModelManuscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManuscriptsQuery = {
  listManuscripts?:  {
    __typename: "ModelManuscriptConnection",
    items:  Array< {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetManuscriptTagQueryVariables = {
  id: string,
};

export type GetManuscriptTagQuery = {
  getManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListManuscriptTagsQueryVariables = {
  filter?: ModelManuscriptTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManuscriptTagsQuery = {
  listManuscriptTags?:  {
    __typename: "ModelManuscriptTagConnection",
    items:  Array< {
      __typename: "ManuscriptTag",
      id: string,
      articleID: string,
      tagID: string,
      article?:  {
        __typename: "Manuscript",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        status?: string | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        name: string,
        slug?: string | null,
        categoryID?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        articles?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentOwnerQueryVariables = {
  id: string,
};

export type GetContentOwnerQuery = {
  getContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentOwnersQueryVariables = {
  filter?: ModelContentOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentOwnersQuery = {
  listContentOwners?:  {
    __typename: "ModelContentOwnerConnection",
    items:  Array< {
      __typename: "ContentOwner",
      id: string,
      name: string,
      email: string,
      status?: ActiveStatus | null,
      version: number,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArticleQueryVariables = {
  id: string,
};

export type GetArticleQuery = {
  getArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListArticlesQueryVariables = {
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticlesQuery = {
  listArticles?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListArticleByStatusQueryVariables = {
  status?: string | null,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticleByStatusQuery = {
  listArticleByStatus?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListArticleByStatusAndCategoryQueryVariables = {
  categoryID?: string | null,
  startDateTimeStatus?: ModelArticleListArticleByStatusAndCategoryCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticleByStatusAndCategoryQuery = {
  listArticleByStatusAndCategory?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListArticleByStatusCategoryQueryVariables = {
  categoryID?: string | null,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArticleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticleByStatusCategoryQuery = {
  listArticleByStatusCategory?:  {
    __typename: "ModelArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchArticlesQueryVariables = {
  filter?: SearchableArticleFilterInput | null,
  sort?: SearchableArticleSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchArticlesQuery = {
  searchArticles?:  {
    __typename: "SearchableArticleConnection",
    items:  Array< {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetTopImageQueryVariables = {
  id: string,
};

export type GetTopImageQuery = {
  getTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListTopImagesQueryVariables = {
  filter?: ModelTopImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTopImagesQuery = {
  listTopImages?:  {
    __typename: "ModelTopImageConnection",
    items:  Array< {
      __typename: "TopImage",
      id: string,
      priority?: number | null,
      startDateTime: string,
      endDateTime: string,
      url?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArticleTagQueryVariables = {
  id: string,
};

export type GetArticleTagQuery = {
  getArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListArticleTagsQueryVariables = {
  filter?: ModelArticleTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticleTagsQuery = {
  listArticleTags?:  {
    __typename: "ModelArticleTagConnection",
    items:  Array< {
      __typename: "ArticleTag",
      id: string,
      articleID: string,
      tagID: string,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleConnection",
        items:  Array< {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      tag?:  {
        __typename: "Tag",
        id: string,
        name: string,
        slug?: string | null,
        categoryID?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        articles?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArticleTagNewQueryVariables = {
  id: string,
};

export type GetArticleTagNewQuery = {
  getArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListArticleTagNewsQueryVariables = {
  filter?: ModelArticleTagNewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticleTagNewsQuery = {
  listArticleTagNews?:  {
    __typename: "ModelArticleTagNewConnection",
    items:  Array< {
      __typename: "ArticleTagNew",
      id: string,
      articleID: string,
      tagID: string,
      createdAt: string,
      updatedAt: string,
      article?:  {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tag?:  {
        __typename: "Tag",
        id: string,
        name: string,
        slug?: string | null,
        categoryID?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        articles?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTagByArticleQueryVariables = {
  articleID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArticleTagNewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagByArticleQuery = {
  listTagByArticle?:  {
    __typename: "ModelArticleTagNewConnection",
    items:  Array< {
      __typename: "ArticleTagNew",
      id: string,
      articleID: string,
      tagID: string,
      createdAt: string,
      updatedAt: string,
      article?:  {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tag?:  {
        __typename: "Tag",
        id: string,
        name: string,
        slug?: string | null,
        categoryID?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        articles?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuthorQueryVariables = {
  id: string,
};

export type GetAuthorQuery = {
  getAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListAuthorsQueryVariables = {
  filter?: ModelAuthorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuthorsQuery = {
  listAuthors?:  {
    __typename: "ModelAuthorConnection",
    items:  Array< {
      __typename: "Author",
      id: string,
      name: string,
      email?: string | null,
      status?: ActiveStatus | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      image?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArticlePickupQueryVariables = {
  id: string,
};

export type GetArticlePickupQuery = {
  getArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListArticlePickupsQueryVariables = {
  filter?: ModelArticlePickupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticlePickupsQuery = {
  listArticlePickups?:  {
    __typename: "ModelArticlePickupConnection",
    items:  Array< {
      __typename: "ArticlePickup",
      id: string,
      categoryID?: string | null,
      articleID: string,
      position: Position,
      priority?: number | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      article?:  {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      articles?:  {
        __typename: "ModelArticleConnection",
        items:  Array< {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListArticlePickupByPriorityQueryVariables = {
  priority?: number | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelArticlePickupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArticlePickupByPriorityQuery = {
  listArticlePickupByPriority?:  {
    __typename: "ModelArticlePickupConnection",
    items:  Array< {
      __typename: "ArticlePickup",
      id: string,
      categoryID?: string | null,
      articleID: string,
      position: Position,
      priority?: number | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      article?:  {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      articles?:  {
        __typename: "ModelArticleConnection",
        items:  Array< {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdQueryVariables = {
  id: string,
};

export type GetAdQuery = {
  getAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAdsQueryVariables = {
  filter?: ModelAdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdsQuery = {
  listAds?:  {
    __typename: "ModelAdConnection",
    items:  Array< {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdBannerQueryVariables = {
  id: string,
};

export type GetAdBannerQuery = {
  getAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListAdBannersQueryVariables = {
  filter?: ModelAdBannerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdBannersQuery = {
  listAdBanners?:  {
    __typename: "ModelAdBannerConnection",
    items:  Array< {
      __typename: "AdBanner",
      id: string,
      categoryID?: string | null,
      adID: string,
      position: string,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      ad?:  {
        __typename: "Ad",
        id: string,
        name: string,
        url: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        status: Status,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
      mediaPC?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMediaQueryVariables = {
  id: string,
};

export type GetMediaQuery = {
  getMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListMediasQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediasQuery = {
  listMedias?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchMediasQueryVariables = {
  filter?: SearchableMediaFilterInput | null,
  sort?: SearchableMediaSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchMediasQuery = {
  searchMedias?:  {
    __typename: "SearchableMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetPageQueryVariables = {
  id: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      root: string,
      slug: string,
      title: string,
      discription?: string | null,
      contents?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      backgroundImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPartialHtmlQueryVariables = {
  id: string,
};

export type GetPartialHtmlQuery = {
  getPartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListPartialHtmlsQueryVariables = {
  filter?: ModelPartialHtmlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPartialHtmlsQuery = {
  listPartialHtmls?:  {
    __typename: "ModelPartialHtmlConnection",
    items:  Array< {
      __typename: "PartialHtml",
      id: string,
      name: string,
      contents: string,
      priority: number,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: ActiveStatus,
      createdAt: string,
      updatedAt: string,
      backgroundImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublicationQueryVariables = {
  id: string,
};

export type GetPublicationQuery = {
  getPublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListPublicationsQueryVariables = {
  filter?: ModelPublicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublicationsQuery = {
  listPublications?:  {
    __typename: "ModelPublicationConnection",
    items:  Array< {
      __typename: "Publication",
      id: string,
      name: string,
      link: string,
      priority: number,
      status: ActiveStatus,
      createdAt: string,
      updatedAt: string,
      image?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetYoutubeQueryVariables = {
  id: string,
};

export type GetYoutubeQuery = {
  getYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListYoutubesQueryVariables = {
  filter?: ModelYoutubeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListYoutubesQuery = {
  listYoutubes?:  {
    __typename: "ModelYoutubeConnection",
    items:  Array< {
      __typename: "Youtube",
      id: string,
      videoId: string,
      status: ActiveStatus,
      priority: number,
      startTime?: number | null,
      isAutoPlayed?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFooterQueryVariables = {
  id: string,
};

export type GetFooterQuery = {
  getFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFootersQueryVariables = {
  filter?: ModelFooterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFootersQuery = {
  listFooters?:  {
    __typename: "ModelFooterConnection",
    items:  Array< {
      __typename: "Footer",
      id: string,
      name: string,
      link: string,
      priority: number,
      position: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateManuscriptSubscriptionVariables = {
};

export type OnCreateManuscriptSubscription = {
  onCreateManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateManuscriptSubscriptionVariables = {
};

export type OnUpdateManuscriptSubscription = {
  onUpdateManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteManuscriptSubscriptionVariables = {
};

export type OnDeleteManuscriptSubscription = {
  onDeleteManuscript?:  {
    __typename: "Manuscript",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateManuscriptTagSubscriptionVariables = {
};

export type OnCreateManuscriptTagSubscription = {
  onCreateManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateManuscriptTagSubscriptionVariables = {
};

export type OnUpdateManuscriptTagSubscription = {
  onUpdateManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteManuscriptTagSubscriptionVariables = {
};

export type OnDeleteManuscriptTagSubscription = {
  onDeleteManuscriptTag?:  {
    __typename: "ManuscriptTag",
    id: string,
    articleID: string,
    tagID: string,
    article?:  {
      __typename: "Manuscript",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      status?: string | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateContentOwnerSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateContentOwnerSubscription = {
  onCreateContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentOwnerSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateContentOwnerSubscription = {
  onUpdateContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentOwnerSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteContentOwnerSubscription = {
  onDeleteContentOwner?:  {
    __typename: "ContentOwner",
    id: string,
    name: string,
    email: string,
    status?: ActiveStatus | null,
    version: number,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateArticleSubscriptionVariables = {
};

export type OnCreateArticleSubscription = {
  onCreateArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateArticleSubscriptionVariables = {
};

export type OnUpdateArticleSubscription = {
  onUpdateArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteArticleSubscriptionVariables = {
};

export type OnDeleteArticleSubscription = {
  onDeleteArticle?:  {
    __typename: "Article",
    id: string,
    title: string,
    slug: string,
    description: string,
    descriptionText?: string | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    categoryID: string,
    authorID: string,
    prFlg?: boolean | null,
    adID?: string | null,
    manuscriptID?: string | null,
    status?: string | null,
    connectionID?: string | null,
    version: number,
    relatedArticles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      jName?: string | null,
      slug?: string | null,
      url?: string | null,
      description?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tags?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateTopImageSubscriptionVariables = {
};

export type OnCreateTopImageSubscription = {
  onCreateTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateTopImageSubscriptionVariables = {
};

export type OnUpdateTopImageSubscription = {
  onUpdateTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteTopImageSubscriptionVariables = {
};

export type OnDeleteTopImageSubscription = {
  onDeleteTopImage?:  {
    __typename: "TopImage",
    id: string,
    priority?: number | null,
    startDateTime: string,
    endDateTime: string,
    url?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    jName?: string | null,
    slug?: string | null,
    url?: string | null,
    description?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    mainImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateArticleTagSubscriptionVariables = {
};

export type OnCreateArticleTagSubscription = {
  onCreateArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateArticleTagSubscriptionVariables = {
};

export type OnUpdateArticleTagSubscription = {
  onUpdateArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteArticleTagSubscriptionVariables = {
};

export type OnDeleteArticleTagSubscription = {
  onDeleteArticleTag?:  {
    __typename: "ArticleTag",
    id: string,
    articleID: string,
    tagID: string,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateArticleTagNewSubscriptionVariables = {
};

export type OnCreateArticleTagNewSubscription = {
  onCreateArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateArticleTagNewSubscriptionVariables = {
};

export type OnUpdateArticleTagNewSubscription = {
  onUpdateArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteArticleTagNewSubscriptionVariables = {
};

export type OnDeleteArticleTagNewSubscription = {
  onDeleteArticleTagNew?:  {
    __typename: "ArticleTagNew",
    id: string,
    articleID: string,
    tagID: string,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    tag?:  {
      __typename: "Tag",
      id: string,
      name: string,
      slug?: string | null,
      categoryID?: string | null,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
      articles?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    name: string,
    slug?: string | null,
    categoryID?: string | null,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    articles?:  {
      __typename: "ModelArticleTagNewConnection",
      items:  Array< {
        __typename: "ArticleTagNew",
        id: string,
        articleID: string,
        tagID: string,
        createdAt: string,
        updatedAt: string,
        article?:  {
          __typename: "Article",
          id: string,
          title: string,
          slug: string,
          description: string,
          descriptionText?: string | null,
          startDateTime?: string | null,
          endDateTime?: string | null,
          categoryID: string,
          authorID: string,
          prFlg?: boolean | null,
          adID?: string | null,
          manuscriptID?: string | null,
          status?: string | null,
          connectionID?: string | null,
          version: number,
          relatedArticles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
          category?:  {
            __typename: "Category",
            id: string,
            name: string,
            jName?: string | null,
            slug?: string | null,
            url?: string | null,
            description?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tags?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tag?:  {
          __typename: "Tag",
          id: string,
          name: string,
          slug?: string | null,
          categoryID?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          articles?:  {
            __typename: "ModelArticleTagNewConnection",
            items:  Array< {
              __typename: "ArticleTagNew",
              id: string,
              articleID: string,
              tagID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateAuthorSubscriptionVariables = {
};

export type OnCreateAuthorSubscription = {
  onCreateAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateAuthorSubscriptionVariables = {
};

export type OnUpdateAuthorSubscription = {
  onUpdateAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteAuthorSubscriptionVariables = {
};

export type OnDeleteAuthorSubscription = {
  onDeleteAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    email?: string | null,
    status?: ActiveStatus | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateArticlePickupSubscriptionVariables = {
};

export type OnCreateArticlePickupSubscription = {
  onCreateArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateArticlePickupSubscriptionVariables = {
};

export type OnUpdateArticlePickupSubscription = {
  onUpdateArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteArticlePickupSubscriptionVariables = {
};

export type OnDeleteArticlePickupSubscription = {
  onDeleteArticlePickup?:  {
    __typename: "ArticlePickup",
    id: string,
    categoryID?: string | null,
    articleID: string,
    position: Position,
    priority?: number | null,
    startDateTime?: string | null,
    endDateTime?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    article?:  {
      __typename: "Article",
      id: string,
      title: string,
      slug: string,
      description: string,
      descriptionText?: string | null,
      startDateTime?: string | null,
      endDateTime?: string | null,
      categoryID: string,
      authorID: string,
      prFlg?: boolean | null,
      adID?: string | null,
      manuscriptID?: string | null,
      status?: string | null,
      connectionID?: string | null,
      version: number,
      relatedArticles?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        jName?: string | null,
        slug?: string | null,
        url?: string | null,
        description?: string | null,
        priority?: number | null,
        version: number,
        createdAt: string,
        updatedAt: string,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null,
      tags?:  {
        __typename: "ModelArticleTagNewConnection",
        items:  Array< {
          __typename: "ArticleTagNew",
          id: string,
          articleID: string,
          tagID: string,
          createdAt: string,
          updatedAt: string,
          article?:  {
            __typename: "Article",
            id: string,
            title: string,
            slug: string,
            description: string,
            descriptionText?: string | null,
            startDateTime?: string | null,
            endDateTime?: string | null,
            categoryID: string,
            authorID: string,
            prFlg?: boolean | null,
            adID?: string | null,
            manuscriptID?: string | null,
            status?: string | null,
            connectionID?: string | null,
            version: number,
            relatedArticles?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            category?:  {
              __typename: "Category",
              id: string,
              name: string,
              jName?: string | null,
              slug?: string | null,
              url?: string | null,
              description?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            tags?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
            mainImage?:  {
              __typename: "Media",
              id: string,
              name: string,
              url: string,
              version: number,
              createdAt?: string | null,
              updatedAt: string,
            } | null,
          } | null,
          tag?:  {
            __typename: "Tag",
            id: string,
            name: string,
            slug?: string | null,
            categoryID?: string | null,
            priority?: number | null,
            version: number,
            createdAt: string,
            updatedAt: string,
            articles?:  {
              __typename: "ModelArticleTagNewConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      mainImage?:  {
        __typename: "Media",
        id: string,
        name: string,
        storage:  {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
        },
        url: string,
        version: number,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
    } | null,
    articles?:  {
      __typename: "ModelArticleConnection",
      items:  Array< {
        __typename: "Article",
        id: string,
        title: string,
        slug: string,
        description: string,
        descriptionText?: string | null,
        startDateTime?: string | null,
        endDateTime?: string | null,
        categoryID: string,
        authorID: string,
        prFlg?: boolean | null,
        adID?: string | null,
        manuscriptID?: string | null,
        status?: string | null,
        connectionID?: string | null,
        version: number,
        relatedArticles?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          jName?: string | null,
          slug?: string | null,
          url?: string | null,
          description?: string | null,
          priority?: number | null,
          version: number,
          createdAt: string,
          updatedAt: string,
          mainImage?:  {
            __typename: "Media",
            id: string,
            name: string,
            storage:  {
              __typename: "S3Object",
              bucket: string,
              region: string,
              key: string,
            },
            url: string,
            version: number,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
        } | null,
        tags?:  {
          __typename: "ModelArticleTagNewConnection",
          items:  Array< {
            __typename: "ArticleTagNew",
            id: string,
            articleID: string,
            tagID: string,
            createdAt: string,
            updatedAt: string,
            article?:  {
              __typename: "Article",
              id: string,
              title: string,
              slug: string,
              description: string,
              descriptionText?: string | null,
              startDateTime?: string | null,
              endDateTime?: string | null,
              categoryID: string,
              authorID: string,
              prFlg?: boolean | null,
              adID?: string | null,
              manuscriptID?: string | null,
              status?: string | null,
              connectionID?: string | null,
              version: number,
              relatedArticles?: Array< string | null > | null,
              createdAt: string,
              updatedAt: string,
            } | null,
            tag?:  {
              __typename: "Tag",
              id: string,
              name: string,
              slug?: string | null,
              categoryID?: string | null,
              priority?: number | null,
              version: number,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        mainImage?:  {
          __typename: "Media",
          id: string,
          name: string,
          storage:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          },
          url: string,
          version: number,
          createdAt?: string | null,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateAdSubscriptionVariables = {
};

export type OnCreateAdSubscription = {
  onCreateAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdSubscriptionVariables = {
};

export type OnUpdateAdSubscription = {
  onUpdateAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdSubscriptionVariables = {
};

export type OnDeleteAdSubscription = {
  onDeleteAd?:  {
    __typename: "Ad",
    id: string,
    name: string,
    url: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: Status,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAdBannerSubscriptionVariables = {
};

export type OnCreateAdBannerSubscription = {
  onCreateAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateAdBannerSubscriptionVariables = {
};

export type OnUpdateAdBannerSubscription = {
  onUpdateAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteAdBannerSubscriptionVariables = {
};

export type OnDeleteAdBannerSubscription = {
  onDeleteAdBanner?:  {
    __typename: "AdBanner",
    id: string,
    categoryID?: string | null,
    adID: string,
    position: string,
    priority?: number | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    ad?:  {
      __typename: "Ad",
      id: string,
      name: string,
      url: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      status: Status,
      priority?: number | null,
      version: number,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    mediaPC?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateMediaSubscriptionVariables = {
};

export type OnCreateMediaSubscription = {
  onCreateMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateMediaSubscriptionVariables = {
};

export type OnUpdateMediaSubscription = {
  onUpdateMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteMediaSubscriptionVariables = {
};

export type OnDeleteMediaSubscription = {
  onDeleteMedia?:  {
    __typename: "Media",
    id: string,
    name: string,
    storage:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    url: string,
    version: number,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreatePageSubscriptionVariables = {
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdatePageSubscriptionVariables = {
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeletePageSubscriptionVariables = {
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    root: string,
    slug: string,
    title: string,
    discription?: string | null,
    contents?: string | null,
    status?: string | null,
    version: number,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreatePartialHtmlSubscriptionVariables = {
};

export type OnCreatePartialHtmlSubscription = {
  onCreatePartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdatePartialHtmlSubscriptionVariables = {
};

export type OnUpdatePartialHtmlSubscription = {
  onUpdatePartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeletePartialHtmlSubscriptionVariables = {
};

export type OnDeletePartialHtmlSubscription = {
  onDeletePartialHtml?:  {
    __typename: "PartialHtml",
    id: string,
    name: string,
    contents: string,
    priority: number,
    startDateTime?: string | null,
    endDateTime?: string | null,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    backgroundImage?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreatePublicationSubscriptionVariables = {
};

export type OnCreatePublicationSubscription = {
  onCreatePublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdatePublicationSubscriptionVariables = {
};

export type OnUpdatePublicationSubscription = {
  onUpdatePublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeletePublicationSubscriptionVariables = {
};

export type OnDeletePublicationSubscription = {
  onDeletePublication?:  {
    __typename: "Publication",
    id: string,
    name: string,
    link: string,
    priority: number,
    status: ActiveStatus,
    createdAt: string,
    updatedAt: string,
    image?:  {
      __typename: "Media",
      id: string,
      name: string,
      storage:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      url: string,
      version: number,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateYoutubeSubscriptionVariables = {
};

export type OnCreateYoutubeSubscription = {
  onCreateYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateYoutubeSubscriptionVariables = {
};

export type OnUpdateYoutubeSubscription = {
  onUpdateYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteYoutubeSubscriptionVariables = {
};

export type OnDeleteYoutubeSubscription = {
  onDeleteYoutube?:  {
    __typename: "Youtube",
    id: string,
    videoId: string,
    status: ActiveStatus,
    priority: number,
    startTime?: number | null,
    isAutoPlayed?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFooterSubscriptionVariables = {
};

export type OnCreateFooterSubscription = {
  onCreateFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFooterSubscriptionVariables = {
};

export type OnUpdateFooterSubscription = {
  onUpdateFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFooterSubscriptionVariables = {
};

export type OnDeleteFooterSubscription = {
  onDeleteFooter?:  {
    __typename: "Footer",
    id: string,
    name: string,
    link: string,
    priority: number,
    position: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};
