import React from "react";

import { Tag } from "API";
import { Box, ButtonBase, Typography, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import theme from "theme";
import { navigate } from "gatsby";

type Props = {
  tags: Tag[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  titleBox: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  title: {
    fontWeight: "bold",
  },
  tagsContainer: {
    padding: "8px 50px 8px",
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    justifyContent: "center",
  },
  tag: {
    margin: "16px 8px 16px",
  },
  tagWidth: {
    maxWidth: "1280px",
  },
}));

const CategoryReact = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  padding: 5px 12px;
  color: ${theme.palette.common.black};
  background-color: ${theme.palette.common.white};
  border-radius: 5px;
`;

export default function PickupTags(props: Props) {
  const { tags } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h5" className={classes.title}>
          注目のタグ
        </Typography>
      </Box>

      {tags?.length && (
        <div className={classes.tagsContainer}>
          <div className={classes.tagWidth}>
            {tags.map((tag, _index) => (
              <ButtonBase
                className={classes.tag}
                onClick={() => navigate(`/tags/${tag.slug}`)}
              >
                <CategoryReact>{tag.name}</CategoryReact>
              </ButtonBase>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
