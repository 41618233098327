import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";

import htmlToText from "html-to-text";
const styles = {
  customBox: {
    display: "block",
    overflow: "hidden",
    maxHeight: 120,
    lineHeight: "1.5",

    "@supports (-webkit-line-clamp: 2)": {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 3,
      wordBreak: "break-all",
      overflow: "hidden",
    },
  },
};

const useStyles = makeStyles(styles);

type Props = {
  value: any;
};

export default function HomeHtml2Text(props: Props) {
  const { value } = props;
  const [text, setText] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    // @ts-ignore
    const text = htmlToText.fromString(value, {
      ignoreImage: true,
      ignoreHref: true,
    });
    setText(text);
  }, [value]);

  return <span className={classes.customBox}>{text}</span>;
}
