import React from "react";
import { Article } from "API";
import MainArticleCard from "./card/MainArticleCard";
import { Container } from "@material-ui/core";
import ArticleCardsMobile from "./card/ArticleCardsMobile";

type Props = {
  articles: Article[];
};

export default function NewArticlesMobile(props: Props) {
  const { articles } = props;

  const mainArticle = articles[0];
  const subArticles = articles.slice(1, 5);

  return (
    <>
      <div style={{ paddingTop: "163px" }}>
        {mainArticle && <MainArticleCard article={articles[0]} />}
      </div>
      <Container
        style={{
          boxShadow: "none",
          marginBottom: "50px",
          paddingLeft: "12px",
          paddingRight: "12px",
        }}
      >
        <div>
          <ArticleCardsMobile subArticles={subArticles} />
        </div>
      </Container>
    </>
  );
}

NewArticlesMobile.defaultProps = {
  showCategory: false,
};
