import React from "react";

import { Article } from "API";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import theme from "theme";
import { Link } from "gatsby";
import { useStylesMobile } from "./style";
import CategoryArticlesMobile from "./card/CategoryArticlesMobile";

// ------------------------------------------------------------

type Props = {
  categories: {
    slug: string;
    name: string;
    articles: Article[];
  }[];
};

export default function CategoryComponentMobile(props: Props) {
  const { categories } = props;
  const classes = useStylesMobile();

  const defaultArticles = categories[0].articles;

  const [value, setValue] = React.useState(0);
  const [categorizedArticles, setCategorizedArticles] = React.useState(
    defaultArticles
  );

  const handleChange = (event: any, newValue: number) => {
    setCategorizedArticles(categories[newValue].articles);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h5" className={classes.capitalText}>
          CATEGORY
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          カテゴリ
        </Typography>
      </Box>

      <Paper square style={{ backgroundColor: theme.palette.grey[500] }}>
        <>
          <Tabs
            value={value}
            variant="scrollable"
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            scrollButtons="on"
            centered
            className={classes.tabs}
          >
            {categories.map((category, key) => (
              <Tab
                key={key}
                label={category.name}
                style={
                  value === key
                    ? { color: "white", backgroundColor: "black" }
                    : { color: "white" }
                }
              />
            ))}
          </Tabs>
        </>
      </Paper>

      <div className={classes.tagsContainer}>
        <Grid container spacing={3}>
          {categorizedArticles.map((article: Article, key) => (
            <Grid item xs={6} key={key}>
              <CategoryArticlesMobile article={article} />
            </Grid>
          ))}
        </Grid>

        <Box className={classes.linkContainer}>
          <Link
            to={`/categories/${categories[value]?.slug}`}
            className={classes.linkText}
          >
            他の記事をもっと読む
            <i
              className="fa fa-light fa-chevron-right"
              style={{ marginLeft: "4px" }}
            />
          </Link>
        </Box>
      </div>
    </div>
  );
}

CategoryComponentMobile.defaultProps = {
  showCategory: false,
};
