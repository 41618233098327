import React from "react";
import { Container, Grid } from "@material-ui/core";
import { AdBannersType } from "../../../types/adBanners";
import { Position } from "API";

type Props = {
  position: Position;
  adBanners: AdBannersType;
  isFullWidth?: boolean;
};

function Page(props: Props) {
  const { position, adBanners, isFullWidth = false } = props;
  const _adBanners = adBanners[position];

  return (
    <>
      {_adBanners && _adBanners.length > 0 && (
        <Container
          style={{
            boxShadow: "none",
            marginBottom: "50px",
            padding: "0",
          }}
        >
          <Grid container spacing={3}>
            {_adBanners.map((_adBanner: any, key: any) => (
              <Grid
                item
                xs={12}
                sm={isFullWidth ? 12 : 6}
                key={key}
                style={{ padding: "12px" }}
              >
                <a href={_adBanner.ad.url} rel="noreferrer" target="_blank">
                  {_adBanner.media && (
                    <img
                      src={_adBanner.media.url}
                      alt={_adBanner.media.name}
                      style={{
                        width: "100%",
                        minWidth: "260px",
                      }}
                    />
                  )}
                </a>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default Page;
