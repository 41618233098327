import React from "react";
import { Grid } from "@material-ui/core";
import { Article } from "API";
import Card from "./Card";

// ---------------------------------------------------

type Props = {
  pickUpArticles: Article[];
};
export default function Cards({ pickUpArticles }: Props) {
  return (
    <>
      {pickUpArticles && (
        <Grid container md={12} spacing={2}>
          {pickUpArticles
            .filter((article) => article)
            .map((article: Article, key) => (
              <Card key={key} article={article} />
            ))}
        </Grid>
      )}
    </>
  );
}

Cards.defaultProps = {
  showCategory: false,
};
