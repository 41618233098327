import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArticleDate from "components/atoms/ArticleDate";
import { getCfUrl } from "settings/storageSettings";
import { Article } from "API";
import { Grid } from "@material-ui/core";
import { stylesMobile } from "./styles";

type Props = {
  article: Article;
  isMainArticle?: boolean;
};
// @ts-ignore
const useStyles = makeStyles(stylesMobile);

export default function ArticleCardMobile(props: Props) {
  const { article, isMainArticle = false } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  return (
    <Grid container xs={12} spacing={1} style={{ paddingBottom: "12px" }}>
      <Grid item xs={6}>
        <a
          href={`/articles/${article.slug}`}
          ref={ref}
          style={{
            display: "block",
            overflow: "hidden",
            position: "relative",
            paddingBottom: "66.7%",
          }}
        >
          <img
            loading="lazy"
            src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
            alt={article.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </a>
      </Grid>

      <Grid item xs={6}>
        <a href={`/articles/${article.slug}`}>
          <h4 className={classes.cardTitle}>{article.title}</h4>
        </a>
        {article.startDateTime && (
          <p
            className={classes.textMuted}
            style={{
              paddingTop: isMainArticle ? "4.5rem" : "0.5rem",
            }}
          >
            {article.startDateTime && (
              <ArticleDate date={article.startDateTime} />
            )}
          </p>
        )}
      </Grid>
    </Grid>
  );
}

ArticleCardMobile.defaultProps = {
  showCategory: false,
};
