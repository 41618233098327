import React from "react";
import { Publication } from "API";
import publicationBackgroundImageUrl from "assets/img/home/bg-publication.jpg";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";

import { getCfUrl } from "settings/storageSettings";

type Props = {
  publications: Publication[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: "0px",
  },
  titleBox: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  publicationsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  text: {
    fontWeight: "bold",
  },
  capitalText: {
    letterSpacing: ".2rem",
    fontWeight: "bold",
  },
}));

export default function Publications(props: Props) {
  const { publications } = props;
  const activePublications = publications?.filter(
    (publication) => publication.status === "active"
  );
  const sortedPublications = activePublications.sort(
    (a, b) => b.priority - a.priority
  );
  const topPublications = sortedPublications.slice(0, 6);

  const classes = useStyles();

  let ref = React.useRef(null);

  return (
    <div className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h5" className={classes.capitalText}>
          PUBLICATION
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          発行物
        </Typography>
      </Box>

      <div
        style={{
          backgroundImage: `url("${publicationBackgroundImageUrl}")`,
          backgroundPosition: "top",
        }}
      >
        <div className={classes.publicationsContainer}>
          {topPublications?.map((publication: Publication, i: number) => (
            <Grid
              item
              xs={6}
              style={{
                paddingTop: i <= 1 ? "32px" : "16px",
                paddingBottom: i <= 1 ? "16px" : "32px",
                padding: i % 2 ? "16px 32px 16px 16px" : "16px 16px 16px 32px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {publication && (
                <a href={publication?.link} ref={ref} target="_blank">
                  <img
                    src={
                      publication.image ? getCfUrl(publication.image.url) : ""
                    }
                    style={{ width: "100%", height: "auto", maxWidth: "196px" }}
                    alt={publication.name}
                  />
                </a>
              )}
            </Grid>
          ))}
        </div>
      </div>
    </div>
  );
}
