import React from "react";

import movieBackgroundImageUrl from "assets/img/home/bg-movie.jpg";
import { Button, Grid, Typography } from "@material-ui/core";
import { Youtube } from "API";

type Props = {
  youtubes: {
    priority: number;
    src: string;
  }[];
};

export default function KaziMovie(props: Props) {
  const { youtubes } = props;

  return (
    <div
      style={{
        backgroundImage: `url("${movieBackgroundImageUrl}")`,
        backgroundPosition: "center",
      }}
    >
      <Grid container style={{ padding: "30px", height: "100%" }}>
        <Grid
          item
          xs={5}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={8}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="h5"
                style={{ color: "white", fontWeight: "bold" }}
              >
                舵社Youtubeチャンネル「Kazi Movie」
              </Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  size="large"
                  variant="outlined"
                  style={{
                    border: "1px solid rgb(255,255,255, 0.5)",
                    width: "300px",
                  }}
                  href="https://www.youtube.com/c/kazimovie"
                  target="_blank"
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    動画チャンネルを見る
                  </Typography>
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  size="large"
                  variant="outlined"
                  style={{
                    border: "1px solid rgb(255,255,255, 0.5)",
                    width: "300px",
                  }}
                  href="https://www.kazi-online.com/pages/kazi_movie_boat_index"
                  target="_blank"
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    ヨット＆ボート紹介動画 INDEX
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={7}>
          <Grid container spacing={3}>
            {youtubes?.length &&
              youtubes.map((youtube) => {
                return (
                  <Grid item xs={6} key={youtube.src}>
                    <div
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        height: 0,
                        overflow: "hidden",
                      }}
                    >
                      <iframe
                        // @ts-ignore
                        loading="lazy"
                        title="Kazi Movieの動画"
                        src={youtube.src}
                        frameBorder="0"
                        allowFullScreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </Grid>
                );
              })}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ color: "white", lineHeight: "1.6" }}
              >
                「Kazi Movie」では、弊社で発行しているさまざまな雑誌&nbsp;&nbsp;
                月刊『Kazi』、月刊『Boat CLUB』、『Sea Dream』、『CANOE
                WORLD』、『Premium
                BOATING』&nbsp;などの誌面と連携し、関連記事の動画を少しずつアップしています。
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
