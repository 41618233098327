import React from "react";
import { Article } from "API";
import ArticleCardMobile from "./ArticleCardMobile";

// ---------------------------------------------------

type Props = {
  subArticles: Article[];
};

export default function ArticleCardsMobile({ subArticles }: Props) {
  return (
    <>
      {subArticles
        .filter((article) => article)
        .map((article: Article, key) => (
          <ArticleCardMobile article={article} key={key} />
        ))}
    </>
  );
}

ArticleCardsMobile.defaultProps = {
  showCategory: false,
};
