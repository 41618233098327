import React from "react";

import { Tag } from "API";
import { Box, ButtonBase, Typography, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import theme from "theme";
import { navigate } from "gatsby";

type Props = {
  tags: Tag[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  titleBox: {
    textAlign: "center",
    paddingBottom: "8px",
  },
  title: {
    fontWeight: "bold",
  },
  tagsContainer: {
    padding: "4px 8px 4px",
    backgroundColor: theme.palette.grey[200],
  },
  tag: {
    margin: "8px",
  },
}));

const CategoryReact = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  padding: 8px 16px;
  color: ${theme.palette.common.black};
  background-color: ${theme.palette.common.white};
  border-radius: 5px;
`;

export default function PickupTags(props: Props) {
  const { tags } = props;
  const priorityTags = tags.filter((tag) => tag.priority != null);

  const classes = useStyles();

  priorityTags.sort((a, b) => b.priority - a.priority);

  function compareTags(a, b) {
    if (a.priority === b.priority) {
      return a.name.localeCompare(b.name, "ja");
    }
    return 0;
  }

  const topTags = priorityTags.slice(0, 50).sort(compareTags);

  return (
    <div className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h5" className={classes.title}>
          注目のタグ
        </Typography>
      </Box>

      {topTags?.length && (
        <div className={classes.tagsContainer}>
          {topTags.map((tag, _index) => (
            <ButtonBase
              className={classes.tag}
              onClick={() => navigate(`/tags/${tag.slug}`)}
            >
              <CategoryReact>{tag.name}</CategoryReact>
            </ButtonBase>
          ))}
        </div>
      )}
    </div>
  );
}
