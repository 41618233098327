import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getCfUrl } from "settings/storageSettings";
import { Article } from "API";
import { Box, ButtonBase, Grid } from "@material-ui/core";
import styled from "styled-components";
import { navigate } from "gatsby";
import theme from "theme";
import { styles } from "./style";

// ------------------------------------------------------------

const CategoryReact = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  color: ${theme.palette.common.black};
  background-color: ${theme.palette.grey[300]};
`;

type Props = {
  article: Article;
};

// @ts-ignore
const useStyles = makeStyles(styles);

export default function CardMobile(props: Props) {
  const { article } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  return (
    <>
      <Grid item xs={12} style={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <a
              href={`/articles/${article.slug}`}
              ref={ref}
              style={{
                display: "block",
                overflow: "hidden",
                position: "relative",
                paddingBottom: "66.7%",
              }}
            >
              <img
                loading="lazy"
                className={classes.imgCardTop}
                src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
                alt={article.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </a>
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "-12px",
            }}
          >
            <a href={`/articles/${article.slug}`}>
              <h4 className={classes.cardTitle}>{article.title}</h4>
            </a>

            {article.category && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonBase
                  style={{ width: "100px" }}
                  onClick={() =>
                    navigate(`/categories/${article.category?.slug}`)
                  }
                >
                  <CategoryReact>{article.category.name}</CategoryReact>
                </ButtonBase>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

CardMobile.defaultProps = {
  showCategory: false,
};
