import React from "react";
import { Box, Typography } from "@material-ui/core";
import Container from "components/atoms/Container";
import { useStyles } from "./style";
import CardsMobile from "./card/CardsMobile";

// ---------------------------------------------------

type Props = {
  pickUpArticles: any[];
};

export default function PopularArticlesMobile({ pickUpArticles }: Props) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.titleContainer}>
        <Box style={{ textAlign: "center" }}>
          <Typography variant="h5" className={classes.titleText}>
            POPULAR
          </Typography>
          <Typography className={classes.subTitleText}>人気記事</Typography>
        </Box>
      </Box>

      <Container style={{ boxShadow: "none", marginBottom: "50px" }}>
        <div>
          <CardsMobile pickUpArticles={pickUpArticles} />
        </div>
      </Container>
    </>
  );
}
