import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArticleDate from "components/atoms/ArticleDate";
import { getCfUrl } from "settings/storageSettings";
import { Article } from "API";
import { Box } from "@material-ui/core";
import HomeHtml2Text from "components/atoms/HomeHtml2Text";
import { styles } from "./styles";

type Props = {
  article: Article;
  isMainArticle?: boolean;
};

// @ts-ignore
const useStyles = makeStyles(styles);

export default function ArticleCard(props: Props) {
  const { article, isMainArticle = false } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  return (
    <>
      <div className={classes.root}>
        <Box>
          <a href={`/articles/${article.slug}`} ref={ref} className={classes.a}>
            <img
              loading="lazy"
              src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
              alt={article.title}
              className={classes.img}
            />
          </a>
        </Box>

        <Box>
          <a href={`/articles/${article.slug}`}>
            <h4
              className={classes.cardTitle}
              style={{
                fontSize: isMainArticle ? "20px" : "15px",
              }}
            >
              {article.title}
            </h4>
          </a>

          {isMainArticle && (
            <p className={classes.descriptionText}>
              <HomeHtml2Text value={article.description} />
            </p>
          )}

          {article.startDateTime && (
            <p
              className={classes.textMuted}
              style={{
                paddingTop: isMainArticle ? "2.5rem" : "0rem",
              }}
            >
              {article.startDateTime && (
                <ArticleDate date={article.startDateTime} />
              )}
            </p>
          )}
        </Box>
      </div>
    </>
  );
}

ArticleCard.defaultProps = {
  showCategory: false,
};
