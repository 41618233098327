import { makeStyles } from "@material-ui/core";
// --------------------------------------------------
export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
  },
  titleText: {
    color: theme.palette.common.white,
    fontWeight: "bold",
    letterSpacing: ".2rem",
  },
  subTitleText: {
    color: theme.palette.common.white,
    marginTop: "4px",
  },
  titleContainer: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pickupContainer: {
    paddingTop: "12px",
  },
}));
