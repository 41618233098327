import React from "react";
import { Article } from "API";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import theme from "theme";
import { Link } from "gatsby";
import { useStyles } from "./style";
import { StyledTab, StyledTabs } from "./styleTab";
import CategoryArticles from "./card/CategoryArticles";

// ------------------------------------------------------------

type Props = {
  categories: {
    slug: string;
    name: string;
    articles: Article[];
  }[];
};

export default function CategoryComponent(props: Props) {
  const { categories } = props;
  const classes = useStyles();

  const defaultArticles = categories[0].articles;

  const [value, setValue] = React.useState(0);
  const [categorizedArticles, setCategorizedArticles] = React.useState(
    defaultArticles
  );

  const handleChange = (event: any, newValue: number) => {
    setCategorizedArticles(categories[newValue]?.articles);
    setValue(newValue);
  };

  return (
    <div
      style={{
        boxShadow: "none",
        backgroundColor: "white",
        marginBottom: "50px",
      }}
    >
      <div className={classes.root}>
        <Box className={classes.titleBox}>
          <Typography variant="h4" className={classes.capitalText}>
            CATEGORY
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            カテゴリ
          </Typography>
        </Box>

        <Paper square style={{ backgroundColor: theme.palette.grey[500] }}>
          <StyledTabs
            // @ts-ignore
            value={value}
            onChange={handleChange}
            aria-label="カテゴリ記事一覧のタブ"
            variant="fullWidth"
          >
            {categories.map((category) => (
              <StyledTab
                // @ts-ignore
                label={category.name}
              />
            ))}
          </StyledTabs>
        </Paper>

        <div className={classes.tagsContainer}>
          <div className={classes.contentsWrapper}>
            <Grid container spacing={3}>
              {categorizedArticles.map((article: Article, key) => (
                <Grid item xs={3} key={key}>
                  <CategoryArticles article={article} isMain={false} />
                </Grid>
              ))}
            </Grid>

            <Box className={classes.linkContainer}>
              <Link
                to={`/categories/${categories[value]?.slug}`}
                className={classes.linkText}
              >
                他の記事をもっと読む
                <i
                  className="fa fa-light fa-chevron-right"
                  style={{ marginLeft: "4px" }}
                />
              </Link>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
CategoryComponent.defaultProps = {
  showCategory: false,
};
