import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArticleDate from "components/atoms/ArticleDate";
import { getCfUrl } from "settings/storageSettings";
import { Article } from "API";
import { Box } from "@material-ui/core";
import { stylesMobile } from "./styles";

type Props = {
  article: Article;
  isMainArticle?: boolean;
};
// @ts-ignore
const useStyles = makeStyles(stylesMobile);

export default function MainArticleCard(props: Props) {
  const { article } = props;
  const classes = useStyles();

  let ref = React.useRef(null);

  return (
    <>
      <div>
        <Box>
          <a
            href={`/articles/${article.slug}`}
            ref={ref}
            style={{
              display: "block",
              overflow: "hidden",
              position: "relative",
              paddingBottom: "66.7%",
            }}
          >
            <img
              loading="lazy"
              src={article.mainImage ? getCfUrl(article.mainImage.url) : ""}
              alt={article.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </a>
        </Box>

        <Box style={{ padding: "12px" }}>
          <a href={`/articles/${article.slug}`}>
            <h4 className={classes.cardTitle}>{article.title}</h4>
          </a>

          {article.startDateTime && (
            <p className={classes.textMuted}>
              {article.startDateTime && (
                <ArticleDate date={article.startDateTime} />
              )}
            </p>
          )}
        </Box>
      </div>
    </>
  );
}

MainArticleCard.defaultProps = {
  showCategory: false,
};
