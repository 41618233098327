import React from "react";
import { Box, Typography } from "@material-ui/core";
import Container from "components/atoms/Container";
import { useStyles } from "./style";
import Cards from "./card/Cards";

// --------------------------------------------------
type Props = {
  pickUpArticles: any[];
};

export default function PopularArticles({ pickUpArticles }: Props) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.titleContainer}>
        <Box style={{ textAlign: "center" }}>
          <Typography variant="h4" className={classes.titleText}>
            POPULAR
          </Typography>
          <Typography className={classes.subTitleText}>人気記事</Typography>
        </Box>
      </Box>

      <Container style={{ boxShadow: "none", marginBottom: "50px" }}>
        <Box className={classes.pickupContainer}>
          <div>
            <Cards pickUpArticles={pickUpArticles} />
          </div>
        </Box>
      </Container>
    </>
  );
}
