import React from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";
import theme from "theme";

export const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.common.black,
    "&:span": {
      color: theme.palette.common.black,
      width: "100%",
      backgroundColor: theme.palette.common.black,
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    borderBottom: `3px solid black`,
    "&:focus": {
      opacity: 1,
    },
  },
  selected: {
    backgroundColor: `${theme.palette.common.black}`,
  },
}))((props) => <Tab disableRipple {...props} />);
