import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Article } from "API";
import ArticleCard from "./card/ArticleCard";

type Props = {
  articles: Article[];
};

export default function NewArticles(props: Props) {
  const { articles } = props;

  const mainArticle = articles[0];
  const subArticles = articles.slice(1, 5);

  return (
    <div style={{ paddingTop: "165px", marginBottom: "50px" }}>
      <Container style={{ boxShadow: "none" }}>
        <Grid container spacing={3}>
          {mainArticle && (
            <Grid item xs={6}>
              <ArticleCard article={mainArticle || []} isMainArticle />
            </Grid>
          )}

          {subArticles && (
            <Grid item xs={6}>
              <Grid container spacing={3}>
                {subArticles
                  .filter((article) => article)
                  .map((article: Article, key) => (
                    <Grid item xs={6} key={key}>
                      <ArticleCard article={article} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
}

NewArticles.defaultProps = {
  showCategory: false,
};
